import { render, staticRenderFns } from "./DetailEvent.vue?vue&type=template&id=5b1ce03a&scoped=true"
import script from "./DetailEvent.vue?vue&type=script&lang=js"
export * from "./DetailEvent.vue?vue&type=script&lang=js"
import style0 from "./DetailEvent.vue?vue&type=style&index=0&id=5b1ce03a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b1ce03a",
  null
  
)

export default component.exports